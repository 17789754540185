import React, { useState, useContext, useEffect } from "react"
import styled from "@emotion/styled"

import { Link } from "gatsby"
import LocationSelect from "./LocationSelect"
import { LocationContext } from "../contexts/LocationContext"

const Container = styled.section`
  max-width: 130rem;
  padding: 3rem 1.5rem;
  margin: 0 auto;
`

const Content = styled.div`
  padding: 3rem 0;
`

const Primary = styled.h2`
  text-align: center;
  font-size: 3.5rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.3;
  color: #8cc949;
  background: linear-gradient(to right, #8cc949, #78B526);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
`

const Subtitle = styled.h3`
  font-size: 1.9rem;
  font-weight: 400;
  text-align: center;
  padding: 1.5rem 10rem;
  @media only screen and (max-width: 52em) {
    padding: 1.5rem 0;
  }
`

const SelectWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    margin-right: 1rem;
  }
`

const Wrapper = styled.table`
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 2px 6px rgba(0, 21, 64, 0.25);
  @media only screen and (max-width: 45em) {
    display: block;
    overflow-x: scroll;
  }
`

const Header = styled.thead`
  background-color: #fafafa;
`

const Row = styled.tr``

const Head = styled.th`
  padding: 2rem;
  min-width: 15rem;
  font-weight: 400;
  text-align: left;

  color: ${props => props.redText ? "red" : "#8cc949"};
`

const StateTerritory = styled(Head)`
  font-weight: 700;
  background-color: #afafaf;
  color: white
`

const TBody = styled.tbody`
  tr {
    &:nth-of-type(2n + 1) {
      background-color: rgba(0, 81, 193, 0.05);
    }
  }
`

const Cell = styled.td`
  padding: 2rem;
  &:nth-of-type(odd) {
    background-color: rgba(0, 81, 193, 0.05);
  }
  min-width: 21rem;
`

const List = styled.ul`
  padding-left: 2rem;
`

const CommodityTable = ({ data: { type, title, subtitle, data } }) => {
  const { loc } = useContext(LocationContext)

  function getSelectedCommodityStateData() {
    let stateData = data.find(obj => obj.state == loc.province)
    if (!stateData) {
      return data[0]
    }
    return stateData;
  }

  const [selectedCommodityStateData, setSelectedCommodityStateData] = useState(() => getSelectedCommodityStateData())

  useEffect(() => {
    setSelectedCommodityStateData(getSelectedCommodityStateData());
  }, [loc]);

  function toSentenceCase(string) {
    const text = string.replace(/([A-Z])/g, " $1")
    return text.charAt(0).toUpperCase() + text.slice(1)
  }

  function renderStateHeader() {
    return (
      <StateTerritory colSpan="3">
        {selectedCommodityStateData ?
          toSentenceCase(selectedCommodityStateData.state) :
          toSentenceCase(data[0].state)}
      </StateTerritory>
    )
  }

  function renderPropertyList(commodityItemsArr) {
    let commodityItemsObj = commodityItemsArr.reduce((acc, curr) => {
      let splitItm = curr.split(" | ")
      let main = splitItm[0],
        sub = splitItm[1]

      acc[main] = acc[main] || []

      if (sub) {
        acc[main].push(sub)
      }

      return acc
    }, {})

    return (
      <List>
        {Object.keys(commodityItemsObj).map(main => (
          <React.Fragment>
            <li>{main}</li>
            {commodityItemsObj[main].length > 0 &&
              <List>
                {commodityItemsObj[main].map(sub => (
                  <li>{sub}</li>
                ))}
              </List>
            }
          </React.Fragment>
        ))}
      </List>
    )
  }

  return (
    <Container>
      <Primary>{title}</Primary>
      <Subtitle>{subtitle}</Subtitle>
      <SelectWrapper>
        <div>Not your city?</div>
        <LocationSelect color="#505050" fontSize="2.5rem" />
      </SelectWrapper>
      <Content>
        <Wrapper>
          <col style={{ width: '25%' }} />
          <col style={{ width: '50%' }} />
          <col style={{ width: '25%' }} />
          <Header>
            <Row>{renderStateHeader()}</Row>
          </Header>
          <TBody>
            <Row>
              <Head>{type} Covered</Head>
              <Cell>{renderPropertyList(selectedCommodityStateData['yes'])}</Cell>
              <Cell>{renderPropertyList(selectedCommodityStateData['depositFees'])}</Cell>
            </Row>
            <Row>
              <Head redText>{type} Not Included</Head>
              <Cell>{renderPropertyList(selectedCommodityStateData['no'])}</Cell>
              <Cell>
                <List>
                  <li>$0 AUD</li>
                </List>
              </Cell>
            </Row>
          </TBody>
        </Wrapper>
      </Content>
    </Container>
  )
}

export default CommodityTable
